import { template as template_2595dfc025bf49b1ae170cb26bc7a812 } from "@ember/template-compiler";
const SidebarSectionMessage = template_2595dfc025bf49b1ae170cb26bc7a812(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
