import { template as template_6904c42b2e6c4cfd966aa7582e5b2e46 } from "@ember/template-compiler";
const FKControlMenuContainer = template_6904c42b2e6c4cfd966aa7582e5b2e46(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
