import { template as template_16a412af306b4cf19824d9fed182e2fa } from "@ember/template-compiler";
const WelcomeHeader = template_16a412af306b4cf19824d9fed182e2fa(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
