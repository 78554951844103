import { template as template_71e2624c2a5a4206949e2283b9bb6349 } from "@ember/template-compiler";
const EmptyState = template_71e2624c2a5a4206949e2283b9bb6349(`
  <div class="empty-state-container">
    <div class="empty-state">
      <span data-test-title class="empty-state-title">{{@title}}</span>
      <div class="empty-state-body">
        <p data-test-body>{{@body}}</p>
      </div>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default EmptyState;
