import { template as template_e53ccbafa0494c5a9aa9b2f32f339a8a } from "@ember/template-compiler";
const FKLabel = template_e53ccbafa0494c5a9aa9b2f32f339a8a(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
